
    import { Component, Prop, Vue } from "vue-property-decorator";
    import RoutesEnum from "@/router/routesEnum";
    import moment from "moment";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import { PaymentRedirectInvoiceQuery, PaymentURLModel } from "@/apiManager/payment/paymentData";
    import { InvoiceModel, InvoiceQuery, InvoiceReceiptModel } from "@/apiManager/invoice/invoiceData";
    import LanguageModel from "@/apiManager/_common/languageModel";
    import { RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class OutstandingInvoiceDetail extends Vue
    {
        @Prop({ type: String }) private file!: string;

        private invoice: string = "";
        private dateDepart: string = "";
        private dateReturn: string = "";
        private destination: LanguageModel = new LanguageModel();
        private total: number = 0;
        private balance: number = 0;
        private currency: string = "";
        private payments: InvoiceReceiptModel[] = [];

        private amountInput: number = 0;
        private errorCode: string = "";

        get amountInputProxy(): number
        {
            return this.amountInput;
        }
        set amountInputProxy(value: number)
        {

            if (isNaN(value) || typeof value !== "number")
            {
                this.amountInput = 0;
            }
            else
            {
                let valueFiltred: number = parseFloat(value.toFixed(2));
                this.amountInput = valueFiltred;
            }
        }

        get getPaymentTotal(): number
        {
            return this.payments.reduce(
                (accumulator: number, payment: InvoiceReceiptModel) =>
                {
                    return accumulator + payment.amount;
                }, 0);
        }

        get getErrorMessage(): string
        {
            if (this.errorCode.length > 0)
            {
                return this.i18n(this.errorCode as RezToursLanguageEnum) ?? "";
            }
            else
            {
                return "";
            }
        }

        get g_PaymentsByInvoice(): Record<string, InvoiceReceiptModel[]>
        {
            let result: Record<string, InvoiceReceiptModel[]> = {};

            this.payments.forEach(
                (payment) =>
                {
                    if (!result[payment.invoice])
                    {
                        result[payment.invoice] = [];
                    }
                    result[payment.invoice].push(payment);
                }
            );

            return result;
        }

        async mounted()
        {
            if (this.file === null || this.file.length === 0)
            {
                this.$router.replace({ name: RoutesEnum.OutstandingInvoiceList });
            }

            try
            {
                let query: InvoiceQuery = new InvoiceQuery(this.file);
                let outstandingInvoiceDetail: InvoiceModel = await this.$apiManager.invoice.getDetailAsync(query);

                this.invoice = outstandingInvoiceDetail.invoice;
                this.dateDepart = moment(outstandingInvoiceDetail.dateDepart).format(this.$tStore.state.configState.setting.license.dateFormat);
                this.dateReturn = moment(outstandingInvoiceDetail.dateReturn).format(this.$tStore.state.configState.setting.license.dateFormat);
                this.destination = outstandingInvoiceDetail.destination;
                this.balance = outstandingInvoiceDetail.balance;
                this.total = outstandingInvoiceDetail.total;
                this.currency = outstandingInvoiceDetail.currency;
                this.payments = outstandingInvoiceDetail.listReceipt;

                if (this.balance <= 0)
                {
                    this.amountInputProxy = 0;
                }
                else
                {
                    this.amountInputProxy = this.balance;
                }
            }
            catch(ex)
            {
                this.$router.replace({ name: RoutesEnum.Package });
            }
        }

        isAddPaymentValid()
        {
            if (isNaN(this.amountInputProxy))
            {
                this.errorCode = this.getRezToursLanguageEnum.paymentIsNumber;
                return false;
            }

            if (this.amountInputProxy <= 0)
            {
                this.errorCode = this.getRezToursLanguageEnum.paymentNotNegative;
                return false;
            }

            this.errorCode = "";
            return true;
        }

        onClickGoHome()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }

        onClickGoBack()
        {
            this.$router.push({ name: RoutesEnum.OutstandingInvoiceList });
        }

        async onClickPay()
        {
            if (this.isAddPaymentValid())
            {
                try
                {
                    let query: PaymentRedirectInvoiceQuery = new PaymentRedirectInvoiceQuery();
                    query.invoice = this.invoice;
                    query.amount = this.amountInputProxy;
                    query.lang = this.g_Language;

                    let paymentURL: PaymentURLModel = await this.$apiManager.payment.getURLInvoiceAsync(query);
                    sessionStorage.setItem((process.env?.VUE_APP_LICENSE ?? "") + "_invoiceCode", this.file);
                    window.location.replace(paymentURL.url);
                }
                catch
                {
                    this.errorCode = this.getRezToursLanguageEnum.errorCommPCVWeb;
                }
            }
        }

        get components()
        {
            let components =
                {
                    "format-money": FormatMoney
                };
            return components;
        }

    }
